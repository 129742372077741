div.card-home{
    height: 90%;
}

@media screen and (max-width: 1350px){
    .card-home > img{
        width: 35% !important;
    }
}

@media screen and (max-width: 1100px){
    .card-home > img{
        width: 50% !important;
    }
}

@media screen and (max-width: 750px){
    .card-home > img{
        width: 60% !important;
    }
}

@media screen and (max-width: 550px){
    .card-home{
        img{
            width: 70% !important;
        }

        h2{
            font-size: 1.3rem;
        }
    }
}

@media screen and (max-width: 450px){
    .card-home{
        img{
            width: 85% !important;
        }

        h2{
            font-size: 1rem;
        }
    }
}