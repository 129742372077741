main.home-page{
    height: auto;
    width: 100vw;
    background-color: #1C1C1C;
    color: white;

    div.image-background{
        width: 100vw;
        height: 100vh;
        background-image: linear-gradient(#000000df, #13130adb), url("../assets/background-home.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        backface-visibility: hidden;
    }
}