div.modal-background{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;

    .close-button{
        top: 7%;
        left: 95%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        z-index: 3;
    }

    div.modal-container{
        top: 50%;
        left: 50%;
        object-fit: cover;
        object-position: center;
        transform: translate(-50%, -50%);
        border-radius: 10px;

        img{
            max-height: 100vh !important;
            max-width: 100vw !important;
        }
    }
}

@media screen and (max-width: 1200px){
    div.modal-container{
        width: 60% !important;
    }
}

@media screen and (max-width: 768px){
    .close-button{
        top: 4% !important;
        left: 96% !important;
    }

    div.modal-container{
        width: 100% !important;
        height: 100%;

        img{
            border-radius: 0px !important;
        }
    }
}

@media screen and (max-width: 550px){
    .close-button{
        top: 4% !important;
        left: 94% !important;
    }
}