div.whatsapp-component {
  width: 6vw;
  height: 8vh;
  top: 93%;
  left: 95%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  z-index: 3;

  .whatsapp-icon {
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
  }

  .whatsapp-icon:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@media screen and (max-width: 1440px){
  div.whatsapp-component {
    width: 8vw;
    height: 10vh;
    top: 92%;
    left: 94%;
    }
}

@media screen and (max-width: 1024px){
  div.whatsapp-component {
    width: 10vw;
    height: 12vh;
    top: 90%;
    left: 92%;
    }
}

@media screen and (max-width: 768px){
  div.whatsapp-component {
    width: 13vw;
    height: 15vh;
    top: 89%;
    left: 90%;
    }
}

@media screen and (max-width: 550px){
  div.whatsapp-component {
    width: 18vw;
    height: 20vh;
    top: 89%;
    left: 89%;
    }
}
